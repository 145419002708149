<template>
    <div class="p-grid">
      <div class="p-col-12">
        <Panel header="Gerenciamento de Tipos de Vínculos">
          <Toolbar class="p-mb-4">
            <template #start>
              <Button
                label="Novo"
                icon="pi pi-plus"
                class="mr-2"
                @click="showCreate()"
              />
            </template>
            <template #end>
              <div
                class="
                  table-header
                  p-d-flex p-flex-column p-flex-md-row p-jc-md-between
                "
              >
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filters['global'].value"
                    placeholder="Buscar..."
                  />
                </span>
              </div>
            </template>
          </Toolbar>
          <DataTable
            ref="dt"
            dataKey="id"
            class="p-datatable-sm"
            :value="typeBonds"
            :paginator="true"
            :rows="10"
            :rowsPerPageOptions="[10, 20, 50]"
            :filters="filters"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo de {first} a {last} no total de {totalRecords} tipos de vínculos."
            :loading="loading"
          >
            <template #empty>
              <div class="text-center">Nenhum resultado encontrado...</div>
            </template>
            <Column header="Descrição" field="description" :sortable="true">
              <template #body="slotProps">
                {{ slotProps.data.description }}
              </template>
            </Column>
            <Column header="Ações">
              <template #body="slotProps">
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-success mr-2"
                  @click="showUpdate(slotProps.data)"
                  v-tooltip.top="'CLIQUE PARA EDITAR'"
                />
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-warning"
                  @click="showRemove(slotProps.data)"
                  v-tooltip.top="'CLIQUE PARA EXCLUIR'"
                />
              </template>
            </Column>
          </DataTable>
        </Panel>
      </div>
      <ConfirmDialog></ConfirmDialog>
    </div>
  
    <!--MODAL CADASTRAR-->
    <dialog-form :typeBondSelected="typeBond" @findAll="findAll" />
    <!--FIM MODAL CADASTRAR-->
  </template>
  
  <script>
  import { FilterMatchMode } from "primevue/api";
  
  //models
  import TypeBond from "../../models/type_bond"
  
  //Services
  import TypeBondService from "../../service/type_bond/type_bond_service";
  
  //components
  import DialogForm from "./components/dialog-form.vue";
  
  export default {
    components: {
      DialogForm,
    },
    data() {
      return {
        loading: false,
        typeBonds: [],
        typeBond: new TypeBond(),
        permissoes: [],
        filters: {},
        filtersPermissions: {},
        typeBondService: new TypeBondService(),
      };
    },
    created() {
      this.initFilters();
    },
    mounted() {
      this.findAll();
    },
    methods: {
      showCreate() {
        this.typeBond = new TypeBond();
        this.$store.state.views.typeBond.dialogForm = true;
      },
      showUpdate(typeBond) {
        this.typeBond = typeBond;
        this.$store.state.views.typeBond.dialogForm = true;
      },
      showRemove(typeBond) {
        this.$confirm.require({
          message: "Deseja Excluir esse registro?",
          header: "Deseja deletar?",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Sim",
          rejectLabel: "Não",
          accept: () => {
            this.typeBondService
              .delete(typeBond.id)
              .then((data) => {
                this.$msgSuccess(data);
                this.findAll();
              })
              .catch((error) => {
                this.$msgErro(error);
              });
          },
        });
      },
      findAll() {
        this.loading = true;
        this.typeBondService.findAll().then((data) => {
          this.typeBonds = data;
          this.loading = false;
        });
      },
      initFilters() {
        this.filters = {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
        this.filtersPermissions = {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import url("../../App.scss");
  </style>
  